var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"O8eCB1imhd-tHWIxhusm0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import * as LDClient from 'launchdarkly-js-client-sdk';

import { clientValidatedEnv } from './src/config/env.client';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const replay = new Sentry.Replay({
    maskAllText: true,
    maskAllInputs: true,
    unmask: ['[data-non-pii]'],
    mask: ['[data-pii]'],
});

export class SentryService {
    static async initiate() {
        const environment = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT ?? 'local';

        if (environment === 'local' || environment === 'test') {
            // can be used to start/stop replays manually
            // eslint-disable-next-line max-len
            // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#start-and-stop-recording
            window['sentryReplay'] = replay;

            window['sentryErrorTest'] = () => {
                throw new Error('Sending a sentry test error!');
            };
        }

        const ldClient = LDClient.initialize(
            clientValidatedEnv.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_KEY,
            {
                kind: 'user',
                key: 'all',
                custom: {
                    url: window.location.href,
                },
            },
        );

        await ldClient.waitForInitialization();

        Sentry.init({
            environment,
            debug:
                process.env.NEXT_PUBLIC_SENTRY_DEBUG === 'true' ||
                process.env.NEXT_PUBLIC_SENTRY_DEBUG === '1',
            maxValueLength: 3000,
            sampleRate: await ldClient.variation(
                'besiktningsforetaget-config-sentry-sample-rate',
                (() => {
                    if (environment === 'test') {
                        return 0.5;
                    }
                    if (environment === 'production') {
                        return 0.2;
                    }
                    return 0.0;
                })(),
            ),
            dsn: SENTRY_DSN,
            ignoreErrors: [/^Network Error$/],
            tracesSampler: await ldClient.variation(
                'besiktningsforetaget-config-sentry-transaction-sample-rate',
                (() => {
                    if (environment === 'production') {
                        return 0.1;
                    }
                    if (environment === 'test') {
                        return 0.1;
                    }

                    return 0.0;
                })(),
            ),
            replaysSessionSampleRate: await ldClient.variation(
                'besiktningsforetaget-config-sentry-replay-sample-rate',
                (() => {
                    if (environment === 'production') {
                        return 0.0;
                    }
                    if (environment === 'test') {
                        return 0.0;
                    }

                    return 0.0;
                })(),
            ),
            replaysOnErrorSampleRate: await ldClient.variation(
                'besiktningsforetaget-config-sentry-replay-error-sample-rate',
                (() => {
                    if (environment === 'production') {
                        return 0.0;
                    }
                    if (environment === 'test') {
                        return 0.0;
                    }

                    return 0.0;
                })(),
            ),
            integrations: [replay],
        });
    }
}

SentryService.initiate();
